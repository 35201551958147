// HELPER
import {
  updateObject,
  WHDefectEntryDOM,
  WHFeatureKeyENUM,
  WHIconENUM,
  WHLoginDataService,
  WHNgxToastrENUM,
} from '@workheld/workheld-shared-lib';

// ANGULAR
import { FormGroup } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  effect,
  signal,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// SERVICES
import { EntryAsyncService } from 'src/app/app-services-async/entry-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { WHNgxToastrService } from '@workheld/workheld-shared-lib';
import { FormReferenceService } from 'src/app/app-services-helper/form-reference.service';

// RxJS 6
import { Subscription, throwError } from 'rxjs';

// MODELS
import { WHEntryDOM, IWHEntryDTO } from '@workheld/workheld-shared-lib';
import { entryStatusIdEnumArray } from '@workheld/workheld-shared-lib';
import { catchError, map, tap } from 'rxjs/operators';

@Component({
  selector: 'ng-bee-entry-process-form',
  templateUrl: './entry-process-form.component.html',
  styleUrls: ['./entry-process-form.component.scss'],
})
export class EntryProcessFormComponent implements OnInit, OnDestroy {
  // INPUT
  @Input() public entryDOM: WHEntryDOM & { maintenanceNotificationType };

  // OUTPUT
  @Output() public onEntryUpdate: EventEmitter<WHEntryDOM> = new EventEmitter();

  // DATA VAR
  public entryProcessFormGroup: FormGroup;

  // STATE
  public saving: boolean = false;

  // DEP VAR
  public entryStatusIdEnumArray: number[] = entryStatusIdEnumArray;
  public disabledResetTypeIds: number[] = [5];
  public isRevertToDraftEnabled: boolean;

  inventoryType: string;
  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];
  isGenerateWOEnabled = signal(false);
  disableGenerateBtn: boolean;

  constructor(
    private entryAsyncService: EntryAsyncService,
    private dataModelHelperService: DataModelHelperService,
    private ngxToastrService: WHNgxToastrService,
    private loginDataService: WHLoginDataService,
    private formReferenceService: FormReferenceService,
    private translateService: TranslateService,
  ) {
    effect(() => {
      this.inventoryType = this.dataModelHelperService.materialInventoryType();
    });
  }

  ngOnInit(): void {
    this.entryProcessFormGroup =
      this.entryAsyncService.initEntryProcessFormGroup();
    this.entryProcessFormGroup.patchValue(this.entryDOM);
    this.entryProcessFormGroup.get('feedback').disable();

    if (this.entryDOM.entryStatusId === 0 || this.entryDOM.entryStatusId === 3)
      this.entryProcessFormGroup.get('entryStatusId').disable();
    else this.entryProcessFormGroup.get('entryStatusId').enable();

    // FEATURE CONFIG
    this.subscriptions.push(
      this.loginDataService.featureConfigMap$
        .pipe(
          map((features: Map<string, boolean>) => {
            this.isRevertToDraftEnabled = features.get(
              WHFeatureKeyENUM.ENTRY_REVERT_TO_DRAFT,
            );

            this.isGenerateWOEnabled.set(
              features.get(
                WHFeatureKeyENUM.MAINTENANCE_NOTIFICATIONS_GENERATE_WORK_OBJECT,
              ),
            );
          }),
        )
        .subscribe(),
    );
  }

  handleDecline() {
    this.updateEntryStatus(3);
    this.handleSubmit();
  }

  public handleSubmit(): void {
    const entryPayload: IWHEntryDTO = {
      managerComment: this.managerComment,
      entryStatusId: this.entryStatusId,
      feedback: this.feedbackValue,
    } as IWHEntryDTO;

    this.saving = true;
    this.subscriptions.push(
      this.entryAsyncService
        .updateEntryByEntryID(this.entryDOM.id, entryPayload)
        .pipe(
          catchError((error) => {
            this.saving = false;
            return throwError(error);
          }),
        )
        .subscribe((entryDTO: IWHEntryDTO) => {
          this.saving = false;
          const entryDOM: WHEntryDOM =
            this.dataModelHelperService.initEntryDOM(entryDTO);
          this.entryDOM = updateObject(this.entryDOM, entryDOM);
          this.formReferenceService.unsavedChanges = false;
          this.onEntryUpdate.emit(this.entryDOM);
          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey: 'entry.ui.updatesuccess.notification',
          });
        }),
    );
  }

  public updateEntryStatus(changeToStatus = null) {
    if (changeToStatus) {
      this.entryProcessFormGroup.controls['entryStatusId'].patchValue(
        changeToStatus,
      );
    }
    if (this.entryDOM.entryStatusId === this.entryStatusId) {
      return;
    }

    if (this.entryStatusId === 0) {
      this.entryProcessFormGroup.get('feedback')?.enable();
    } else {
      this.entryProcessFormGroup.get('feedback')?.disable();
    }
  }

  public cancelChanges() {
    if (this.entryProcessFormGroup.dirty) {
      const ref = this.formReferenceService.createDialog();
      this.subscriptions.push(
        ref
          .pipe(
            tap((canDiscard) => {
              if (canDiscard) {
                this.onEntryUpdate.emit(null);
              }
            }),
          )
          .subscribe(),
      );
    } else {
      this.onEntryUpdate.emit(null);
    }
  }

  generateWorkObject() {
    this.disableGenerateBtn = true;
    this.entryAsyncService
      .generateWorkObjectFromEntry(this.entryDOM.id)
      .pipe(
        tap((response: any) => {
          this.disableGenerateBtn = false;
          this.onEntryUpdate.emit(this.entryDOM);
          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey: this.translateService.instant(
              'maintenanceplan.ui.successgenerate.notification',
              { extId: response.extId },
            ),
          });
        }),
        catchError((error) => {
          this.disableGenerateBtn = false;
          return throwError(error);
        }),
      )
      .subscribe();
  }

  public get managerComment(): string {
    return this.entryProcessFormGroup.controls['managerComment']
      .value as string;
  }

  public get entryStatusId(): number {
    return this.entryProcessFormGroup.controls['entryStatusId'].value as number;
  }

  public get feedbackValue(): string {
    return this.entryProcessFormGroup.controls['feedback'].value as string;
  }

  public get isResetDisabled(): boolean {
    return this.inventoryType === 'INVENTORY_MANAGED';
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
