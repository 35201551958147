import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { throwError } from 'rxjs';

@Pipe({
  name: 'translate',
  pure: false,
})
export class CustomTranslatePipePipe
  extends TranslatePipe
  implements PipeTransform
{
  translateService: TranslateService = inject(TranslateService);

  transform(key: string, ...args: unknown[]): unknown {
    let result = super.transform(key, args);

    // If the translation is not found in current language,
    // then it tries to find it in the en.json file or else return key
    if (result === '' && key) {
      const currentLang = this.translateService.currentLang;
      try {
        this.translateService.use('en');
        //Get the translation from the en.json file
        const enResult = this.translateService.instant(key);
        //If the translation is not found, then it returns the key
        result = enResult ? enResult : key;
        this.translateService.use(currentLang);
      } catch (error) {
        this.translateService.use(currentLang);
        return key;
      }
    }
    return result;
  }
}
