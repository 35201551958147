<div class="entry-process-form">
  <form [formGroup]="entryProcessFormGroup">
    <div class="entry-process-form-body">
      <span class="flex-1">
        <div class="w-h-label manager-comment-label">
          {{ "entry.model.status" | translate }}
        </div>
        <div class="entry-status-container">
          <div>
            <mat-form-field whFlex="50">
              <mat-label>{{ "entry.model.status" | translate }}</mat-label>
              <mat-select
                formControlName="entryStatusId"
                required
                [disabled]="
                  entryProcessFormGroup.controls['entryStatusId'].disabled
                "
                (selectionChange)="updateEntryStatus()"
              >
                <mat-option
                  *ngFor="
                    let entryStatusId of entryStatusIdEnumArray;
                    let i = index
                  "
                  [value]="entryStatusId"
                  [disabled]="i === 0 && isResetDisabled"
                >
                  {{ "entry.enum.status." + entryStatusId | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            style="display: flex; flex-direction: column"
            *ngIf="isRevertToDraftEnabled"
          >
            <mat-form-field required *ngIf="entryStatusId === 0" whFlex>
              <mat-label>{{
                "entry.model.revertToDraft.quickMessage" | translate
              }}</mat-label>
              <textarea
                formControlName="feedback"
                rows="2"
                placeholder="{{
                  'entry.model.revertToDraft.quickMessage' | translate
                }}"
                matInput
              >
              </textarea>
              <mat-hint align="start">{{
                "entry.model.revertToDraft.warning" | translate
              }}</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </span>
      <span class="flex-1">
        <div class="w-h-label manager-comment-label">
          {{ "entry.model.managercomment" | translate }}
        </div>
        <div>
          <mat-form-field whFlex>
            <textarea
              formControlName="managerComment"
              [disabled]="true"
              rows="5"
              matInput
            >
            </textarea>
          </mat-form-field>
        </div>
      </span>
    </div>
    <div class="detail-actions-wrapper gap--12">
      @if (
        entryDOM?.maintenanceNotificationType &&
        entryDOM?.entryStatusId !== 3 &&
        !entryDOM?.generatedWorkObjectID &&
        isGenerateWOEnabled()
      ) {
        <button
          (click)="generateWorkObject()"
          [disabled]="disableGenerateBtn"
          mat-flat-button
          color="accent"
          class="mat-elevation-z2"
        >
          {{ "ui.acceptandgenerate.label" | translate }}
        </button>
        <button
          (click)="handleDecline()"
          mat-flat-button
          color="warn"
          class="mat-elevation-z2"
        >
          {{ "ui.decline.label" | translate }}
        </button>
      }
      <button
        type="button"
        mat-flat-button
        class="w-h-default-button reset-to-draft-button mat-elevation-z2"
        (click)="cancelChanges()"
        [disabled]="saving"
      >
        <i class="workheld-icons" [innerHTML]="cancelIcon"></i>
        {{ "app.cancel.label" | translate | uppercase }}
      </button>
      <button
        [disabled]="saving || entryProcessFormGroup.invalid"
        type="button"
        mat-flat-button
        color="accent"
        (click)="handleSubmit()"
        class="mat-elevation-z2"
      >
        <mat-spinner
          style="display: inline-block"
          *ngIf="saving"
          [diameter]="18"
        >
        </mat-spinner>
        <i class="workheld-icons" [innerHTML]="saveIcon" *ngIf="!saving"></i>
        {{ "app.save.label" | translate | uppercase }}
      </button>
    </div>
  </form>
</div>
