<app-w-h-chip-list
  *ngIf="chipData"
  [data]="[chipData]"
  [imageKey]="'avatar'"
  [icon]="icon"
  [tooltipKeys]="['extId', 'name']"
  [placeholderImg]="chipData.placeholderImg"
  [titleKey]="'name'"
  [isDisabled]="isDisabled"
  [isRemoveButtonVisible]="true"
  (handleRemoveChip)="unassign()"
></app-w-h-chip-list>
