// ANGULAR
import { Injectable } from '@angular/core';

// RxJS 6
import { BehaviorSubject } from 'rxjs';

// MODELS
import { IWHSearchResultDTO } from '@workheld/workheld-shared-lib';
import { Params } from '@angular/router';
export interface IWHWorkObjectBackButtonModel {
  navRouterLink: string[];
  labelKey: string;
  viewPreset?: string;
  startDate?: Date;
  endDate?: Date;
  workObjectTypeId?: number;
  searchFilter?: IWHSearchResultDTO;
  page?: number;
  term?: string;
  customerId?: string;
  queryParams?: any;
  workTab?: number;
  departmentId?: string;
  toEditView?: boolean;
  viewContext?: string;
}

export class WHWorkObjectBackButton implements IWHWorkObjectBackButtonModel {
  navRouterLink = null;
  labelKey = null;
  viewPreset = null;
  startDate = null;
  endDate = null;
  workObjectTypeId = null;
  searchFilter = null;
  page = null;
  term = null;
  customerId = null;
  departmentId = null;
  workTab = null;
  viewContext? = null;
}

@Injectable()
export class WHRouteBehaviorSubjectService {
  public workObjectBackButtonBehaviorSubject: BehaviorSubject<IWHWorkObjectBackButtonModel> =
    new BehaviorSubject(new WHWorkObjectBackButton());
  public workOrderStateBehaviorSubject: BehaviorSubject<IWHWorkObjectBackButtonModel> =
    new BehaviorSubject(new WHWorkObjectBackButton());
  public assignmentTabGroupStateBehaviorSubject: BehaviorSubject<number> =
    new BehaviorSubject(0);
  public backButtonState: BehaviorSubject<any> = new BehaviorSubject(null);
  public departmentId: string = undefined;
  private backParams: Params = null;

  public get getBackParams() {
    const value = this.backParams;
    this.backParams = null;

    return value;
  }

  public setBackParams(params: Params) {
    this.backParams = params;
  }
}
