<!-- WORKING TIME Entry / entryTypeId = 2 -->
<div class="row-wrapper entry-detail-row-wrapper">
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.model.description" | translate }}
    </div>
    <div class="html-overflow" matTooltip="{{ entryDOM?.title }}">
      {{ entryDOM?.title ? entryDOM?.title : "-" }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.model.createddate" | translate }}
    </div>
    <div>
      {{ entryDOM?.createdDate | date: "short" : "" : currentLocale }}
    </div>
  </span>
  <span>
    <div>
      <ng-bee-worker-static-data
        [withDetailPageNav]="false"
        [nameLabel]="'entry.model.createdbyworker'"
        [withNameLabel]="true"
        [detailURL]="entryDOM?.creatorWorkerDetailURL"
      >
      </ng-bee-worker-static-data>
    </div>
  </span>
  <span>
    <div>
      <ng-bee-worker-static-data
        [withDetailPageNav]="false"
        [nameLabel]="'entry.model.assignedworker'"
        [withNameLabel]="true"
        [detailURL]="entryDOM?.assignedWorkerDetailURL"
      >
      </ng-bee-worker-static-data>
    </div>
  </span>
</div>
<div class="row-wrapper entry-detail-row-wrapper">
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.workingtime.model.startdate" | translate }}
    </div>
    <div class="default-value">
      {{
        workingTimeEntryDOM?.startDate
          ? (workingTimeEntryDOM?.startDate
            | date: "shortDate" : "" : currentLocale)
          : "-"
      }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.workingtime.model.starttime" | translate }}
    </div>
    <div class="default-value">
      {{
        workingTimeEntryDOM?.startDate
          ? (workingTimeEntryDOM?.startDate
            | date: "mediumTime" : "" : currentLocale)
          : "-"
      }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.workingtime.model.duration" | translate }}
    </div>
    <div class="default-value">
      {{
        workingTimeEntryDOM?.durationMinutes ||
        workingTimeEntryDOM?.durationMinutes === 0
          ? (workingTimeEntryDOM?.durationMinutes | duration)
          : ""
      }}
    </div>
  </span>
</div>
<div class="row-wrapper entry-detail-row-wrapper">
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.workingtime.model.enddate" | translate }}
    </div>
    <div class="default-value">
      {{
        workingTimeEntryDOM?.endDate
          ? (workingTimeEntryDOM?.endDate
            | date: "shortDate" : "" : currentLocale)
          : "-"
      }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.workingtime.model.endtime" | translate }}
    </div>
    <div class="default-value">
      {{
        workingTimeEntryDOM?.endDate
          ? (workingTimeEntryDOM?.endDate
            | date: "mediumTime" : "" : currentLocale)
          : "-"
      }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.workingtime.model.break" | translate }}
    </div>
    <div class="default-value">
      {{
        workingTimeEntryDOM?.breakMinutes ||
        workingTimeEntryDOM?.breakMinutes === 0
          ? (workingTimeEntryDOM?.breakMinutes | duration)
          : "-"
      }}
    </div>
  </span>
</div>

<div>
  <div class="w-h-label-no-margine">
    {{ "entry.model.comment" | translate }}
  </div>
  <div>
    <mat-form-field whFlex>
      <textarea
        [disabled]="true"
        rows="2"
        matInput
        [value]="entryDOM?.comment ? entryDOM?.comment : ''"
      >
      </textarea>
    </mat-form-field>
  </div>
</div>

<ng-bee-entry-file-img-gallery
  [entryDOM]="entryDOM"
></ng-bee-entry-file-img-gallery>
