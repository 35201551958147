<!-- PAGE CONTENT ROUTER OUTLET -->

<!-- *ngIf="coordinatorAccess || routeWhitelisted" -->
<div
  *ngIf="coordinatorAccess || routeWhitelisted"
  class="mat-elevation-z1 flex--r-c-sb breadcrumbs-container"
  style="background-color: #fff; z-index: 100; position: relative"
>
  <div class="w-h-page-breadcrumbs">
    <span
      *ngFor="let crumb of breadcrumbs; let i = index; isLast"
      class="w-h-crumb"
      (click)="navigate(crumb)"
    >
      <div class="flex--row">
        <i
          class="workheld-icons"
          style="vertical-align: middle; margin-right: 8px"
          [innerHTML]="crumb?.icon"
        ></i>
        <div class="flex--r-c-c" style="width: 18px" *ngIf="i > 0">-</div>
        {{
          crumb?.nameValue
            ? (crumb?.nameValue | truncate: 36)
            : (crumb?.nameI18nKey | translate | truncate: 36)
        }}
      </div>
      <div
        style="
          font-size: 10px;
          line-height: 0;
          margin-left: 26px;
          margin-top: 1px;
        "
      >
        {{ crumb?.descI18nKey | translate }}
      </div>
    </span>
  </div>
  <div class="right-side-wrapper" *ngIf="displayFilter && canRemoveFilter">
    <app-department-list
      [canRemoveFilter]="canRemoveFilter"
      [departmentList]="departmentDOMList"
      [selectedDepartment]="selectedDepartmentDOM"
      (departmentSelected)="selectionChange($event)"
      (departmentRemoved)="removeFilter()"
    ></app-department-list>
  </div>
</div>

<!-- *ngIf="coordinatorAccess || routeWhitelisted" -->
<div class="w-h-page-content" *ngIf="coordinatorAccess || routeWhitelisted">
  <router-outlet></router-outlet>
</div>

<mat-card
  class="no-access-page"
  *ngIf="!coordinatorAccess && !routeWhitelisted"
>
  <h2
    color="primary"
    class="main-message"
    [innerHTML]="'flow.app.accessrightinformation' | translate"
  ></h2>
  <h3
    class="extra-message"
    [innerHTML]="'flow.app.additionalaccessrightinformation' | translate"
  ></h3>
</mat-card>
