<!-- DEFECT Entry / entryTypeId = 4 -->
<form [formGroup]="entryFormGroup">
  <div class="row-wrapper entry-detail-row-wrapper">
    @if (isEditMode().title) {
      <mat-form-field>
        <mat-label>{{ "entry.model.description" | translate }}</mat-label>
        <input
          data-cy="title-input"
          (blur)="handleSubmit('title')"
          matInput
          formControlName="title"
          required
          autocomplete="off"
        />
      </mat-form-field>
    } @else {
      <span class="flex--r-c gap--4">
        <div class="flex-1" style="min-width: 0">
          <div class="w-h-label entry-detail-label">
            {{ "entry.model.description" | translate }}
          </div>
          <div class="html-overflow" matTooltip="{{ defectEntryDOM?.title }}">
            {{ defectEntryDOM?.title ? defectEntryDOM?.title : "-" }}
          </div>
        </div>
        @if (isEditable()) {
          <button
            *appIsAllowed="{
              permission: 'flow.coordinator.maintenance_notif.write',
            }"
            data-cy="title-edit-button"
            color="accent"
            (click)="toggleEditMode('title')"
            mat-icon-button
          >
            <mat-icon>edit</mat-icon>
          </button>
        }
      </span>
    }
    <span>
      <div>
        <div class="w-h-label entry-detail-label">
          {{ "entry.model.equipment" | translate }}
        </div>
        <app-w-h-chip-list
          *ngIf="bomnode"
          [data]="[bomnode]"
          icon="true"
          [tooltipKeys]="['name']"
          [titleKey]="'name'"
        ></app-w-h-chip-list>
      </div>
    </span>
  </div>
  <div class="row-wrapper entry-detail-row-wrapper">
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.model.createddate" | translate }}
      </div>
      <div>
        {{ defectEntryDOM?.createdDate | date: "short" : "" : currentLocale }}
      </div>
    </span>
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.model.modifiedDate" | translate }}
      </div>
      <div>
        {{
          defectEntryDOM?.modifiedDate
            ? (defectEntryDOM?.modifiedDate
              | date: "short" : "" : currentLocale)
            : "-"
        }}
      </div>
    </span>
    <span>
      <div>
        <div class="w-h-label entry-detail-label">
          {{ "entry.model.createdbyworker" | translate }}
        </div>
        <ng-bee-worker-static-data
          [withDetailPageNav]="false"
          [detailURL]="defectEntryDOM?.creatorWorkerDetailURL"
        >
        </ng-bee-worker-static-data>
      </div>
    </span>
    <span>
      <div>
        <div class="w-h-label entry-detail-label">
          {{ "entry.model.modifiedBy" | translate }}
        </div>
        @if (defectEntryDOM?.lastModifierWorkerDetailURL) {
          <ng-bee-worker-static-data
            [withDetailPageNav]="false"
            [detailURL]="defectEntryDOM?.lastModifierWorkerDetailURL"
          >
          </ng-bee-worker-static-data>
        } @else {
          -
        }
      </div>
    </span>
  </div>
  <div class="row-wrapper entry-detail-row-wrapper">
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.defect.model.defecttype" | translate }}
      </div>
      <div class="default-value">
        {{
          defectEntryDOM?.defectType
            ? ($ENUMS_OBJECT().DEFECT_TYPE[defectEntryDOM?.defectType]
              | translate)
            : "-"
        }}
      </div>
    </span>
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.defect.model.defectcategory" | translate }}
      </div>
      <div class="default-value">
        {{
          defectEntryDOM?.defectCategory
            ? ($ENUMS_OBJECT().DEFECT_CATEGORY[defectEntryDOM?.defectCategory]
              | translate)
            : "-"
        }}
      </div>
    </span>
    @if (isEditMode().errorCode) {
      <mat-form-field>
        <mat-label>{{ "entry.defect.model.errorCode" | translate }}</mat-label>
        <mat-select
          (selectionChange)="handleSubmit('errorCode')"
          formControlName="errorCode"
        >
          <mat-option
            *ngFor="let errorCode of ENUMS.DEFECT_ERROR_CODE | whenum"
            [value]="errorCode.id"
          >
            {{ errorCode.translateKey | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    } @else {
      <span class="flex--r-c gap--4">
        <div class="flex-1">
          <div class="w-h-label entry-detail-label">
            {{ "entry.defect.model.errorCode" | translate }}
          </div>
          <div class="default-value">
            {{
              defectEntryDOM?.errorCode
                ? ($ENUMS_OBJECT().DEFECT_ERROR_CODE[defectEntryDOM?.errorCode]
                  | translate)
                : "-"
            }}
          </div>
        </div>
        @if (isEditable()) {
          <button
            color="accent"
            *appIsAllowed="{
              permission: 'flow.coordinator.maintenance_notif.write',
            }"
            (click)="toggleEditMode('errorCode')"
            mat-icon-button
            data-cy="error-code-edit-button"
          >
            <mat-icon>edit</mat-icon>
          </button>
        }
      </span>
    }
    @if (isEditMode().resolved) {
      <mat-form-field>
        <mat-label>{{ "entry.defect.model.resolved" | translate }}</mat-label>
        <mat-select
          (selectionChange)="handleSubmit('resolved')"
          formControlName="resolved"
        >
          <mat-option
            *ngFor="
              let item of [
                { key: 'entry.defect.ui.resolved', value: true },
                { key: 'entry.defect.ui.notresolved', value: false },
              ]
            "
            [value]="item.value"
          >
            {{ item.key | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    } @else {
      <span class="flex--r-c gap--4">
        <div class="flex-1">
          <div class="w-h-label entry-detail-label">
            {{ "entry.defect.model.resolved" | translate }}
          </div>
          <div class="default-value">
            {{
              (defectEntryDOM?.resolved
                ? "entry.defect.ui.resolved"
                : "entry.defect.ui.notresolved"
              ) | translate
            }}
          </div>
        </div>
        @if (isEditable()) {
          <button
            color="accent"
            *appIsAllowed="{
              permission: 'flow.coordinator.maintenance_notif.write',
            }"
            (click)="toggleEditMode('resolved')"
            mat-icon-button
            data-cy="resolved-edit-button"
          >
            <mat-icon>edit</mat-icon>
          </button>
        }
      </span>
    }
  </div>

  <div class="row-wrapper entry-detail-row-wrapper material-row">
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.defect.model.material" | translate }}
      </div>
      <ng-bee-material-static-data [materialDTO]="materialDTO">
      </ng-bee-material-static-data>
    </span>
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.defect.model.ordernumber" | translate }}
      </div>
      <div class="default-value">
        {{ defectEntryDOM?.orderNumber ? defectEntryDOM?.orderNumber : "-" }}
      </div>
    </span>
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.defect.model.machinetype" | translate }}
      </div>
      <div class="default-value">
        {{ defectEntryDOM?.machineType ? defectEntryDOM?.machineType : "-" }}
      </div>
    </span>
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.defect.model.affectedcostcenter" | translate }}
      </div>
      <div class="default-value">
        {{
          defectEntryDOM?.affectedCostCenter
            ? defectEntryDOM?.affectedCostCenter
            : "-"
        }}
      </div>
    </span>
  </div>

  <div class="row-wrapper entry-detail-row-wrapper">
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.defect.model.timeloss" | translate }}
      </div>
      <div class="default-value">
        {{
          defectEntryDOM?.timeLossMinutes ||
          defectEntryDOM?.timeLossMinutes === 0
            ? (defectEntryDOM?.timeLossMinutes | duration)
            : "-"
        }}
      </div>
    </span>
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.defect.model.maintenanceNotificationType" | translate }}
      </div>
      <div class="default-value">
        {{
          defectEntryDOM?.maintenanceNotificationType
            ? ($ENUMS_OBJECT().MAINTENANCE_NOTIFICATION_TYPE[
                defectEntryDOM?.maintenanceNotificationType
              ] | translate)
            : "-"
        }}
      </div>
    </span>
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.defect.model.completionTime" | translate }}
      </div>
      <div class="default-value">
        {{
          defectEntryDOM?.completionTime
            ? (defectEntryDOM?.completionTime
              | date: "short" : "" : currentLocale)
            : "-"
        }}
      </div>
    </span>
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.defect.model.equipmentDowntime" | translate }}
      </div>
      <div class="default-value">
        {{
          (defectEntryDOM?.equipmentDowntime
            ? "entry.defect.model.hasDownTime"
            : "entry.defect.model.noDownTime"
          ) | translate
        }}
      </div>
    </span>
  </div>

  <div class="row-wrapper entry-detail-row-wrapper desc">
    <span>
      <div class="w-h-label entry-detail-label">
        {{ "entry.defect.model.defectdescription" | translate }}
      </div>
      <div class="default-value" style="padding-right: 16px">
        <mat-form-field class="w-100">
          <textarea
            matInput
            [rows]="2"
            [value]="
              defectEntryDOM?.defectDescription
                ? defectEntryDOM?.defectDescription
                : ''
            "
            disabled
          >
          </textarea>
        </mat-form-field>
      </div>
    </span>
    <span>
      <div class="w-h-label-no-margine">
        {{ "entry.defect.model.workarounddescription" | translate }}
      </div>
      <div>
        <mat-form-field class="w-100" [floatLabel]="'always'">
          <!-- <mat-label>{{ 'ENTRY.DESCRIPTION_LABEL' | translate }}</mat-label> -->
          <textarea
            matInput
            [rows]="2"
            [value]="
              defectEntryDOM?.workaroundDescription
                ? defectEntryDOM?.workaroundDescription
                : ''
            "
            disabled
          >
          </textarea>
        </mat-form-field>
      </div>
    </span>
  </div>

  <div>
    <div class="w-h-label-no-margine">
      {{ "entry.model.comment" | translate }}
    </div>
    <div>
      <mat-form-field class="w-100">
        <textarea
          [disabled]="true"
          rows="2"
          matInput
          [value]="defectEntryDOM?.comment ? defectEntryDOM?.comment : ''"
        >
        </textarea>
      </mat-form-field>
    </div>
  </div>
</form>
<ng-bee-entry-file-img-gallery
  [entryDOM]="entryDOM"
></ng-bee-entry-file-img-gallery>
